<script lang="ts" setup>
import type { HomeBanners } from "@/types";

defineProps<{ banner: HomeBanners[number] }>();
const { open } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
</script>

<template>
	<client-only>
		<MBanner
			:bg-img="banner.backgroundImage"
			:bg-img2x="banner.backgroundImage2x"
			:bg-color="banner.background"
			:image="banner.image"
			:image-mobile="banner.imageMobile"
			:second-image="banner.secondImage"
			:second-image-mobile="banner.secondImageMobile"
			:type="banner.type"
			@click="handleOpenGame(banner.buttonLink)"
		>
			<template #default>
				<AText class="title" variant="takoradi" :modifiers="['medium']">
					<p v-html="banner.title" />
				</AText>
			</template>
			<template #description>
				<AText variant="tlaquepaque" :modifiers="['bold']" class="text-tlalnepantla">
					<p v-html="banner.smallTitle" />
				</AText>
			</template>

			<template #actions>
				<div class="actions">
					<AButton variant="primary" size="xl" class="button">
						<AText variant="tuzla" :modifiers="['semibold']" class="text-tlalnepantla">
							{{ banner.buttonText }}
						</AText>
					</AButton>
				</div>
			</template>
		</MBanner>
	</client-only>
</template>

<style lang="scss" scoped>
:deep {
	.app-banner {
		&__content {
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			padding: gutter(4);

			@include media-breakpoint-down(md) {
				padding: gutter(3) gutter(2);
			}
		}
		&__desc {
			margin-top: gutter(0.5);
		}
	}
}

.title {
	line-height: 28px;
}

.actions {
	width: 280px;
	display: flex;
	flex-direction: column;
	margin-top: gutter(2);
	align-items: center;

	@include media-breakpoint-down(md) {
		width: auto;
		margin-top: 0;
	}

	.button {
		width: 100%;

		@include media-breakpoint-down(md) {
			height: 40px;
			border-radius: 8px;
			margin-top: 0;
		}
	}
}
</style>
